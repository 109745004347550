/* COLORS */
:root {
    --color-black: #222;
    --color-grey-dark: #6a6a6a;
    --color-grey: #C4C4C4;
    --color-grey-light: #DFDFDF;
    --color-white: #EFEFEF;
    --color-pure-white: #FFFFFF;

    --color-red: #ff5747;
    --color-orange: #f2a752;
    --color-yellow: #f5e171;
    --color-blue: #4287f5;
    --color-purple: #b86ded;

    --color-orange-dark: #80582b;
    --color-yellow-dark: #80753b;
    --color-blue-dark: #224680;
    --color-purple-dark: #633b80;

    --color-primary: var(--color-black);
    --color-secondary: var(--color-white);

    --font-heading: 'helvetica neue', sans-serif;
    --font-body: 'helvetica neue', sans-serif;

    --base-vertical-padding: 4.375rem;
    --base-side-padding: 32px;

    --tablet-side-padding: 75px;
    --laptop-side-padding: 150px;
    --desktop-side-padding: 250px;

    --border-radius-primary: 5px;
}

/* MAIN STRUCTURE */

body {
    background: var(--color-secondary);
    color: var(--color-primary);
    font-family: var(--font-body);
    margin: 0 auto;
}

header {
    display: flex;
    justify-content: space-between;
    padding: 2rem var(--base-side-padding);
}

header nav {
    align-self: end;
}

header .logo {
    color: var(--color-primary);
    font-size: 1.625rem;
    font-weight: 700;
    text-decoration: none;
}

main {
    display: flex;
    flex-direction: column;
    padding: 0rem var(--base-side-padding);
}

h1 {
    text-align: center;
}

h1, h2, h3, h4, h5, h6 {
    margin: 2.5rem 0 1rem;
}

section {
    line-height: 1.6rem;
}

footer {
    text-align: center;
}

/* NOTES */
.note {
    background-color: var(--color-grey-light);
    border-radius: var(--border-radius-primary);
    margin-bottom: 1.25rem;
    padding: 1.25rem;
}

.note__link {
    text-decoration: none;
}

.note__link:hover {
    text-decoration: underline solid var(--color-primary);
}

.note__heading {
    color: var(--color-primary);
    margin-top: 0rem;
}

.note__meta {
    margin-bottom: .25rem;
}

/* NOTE DETAILS */
.note-detail__heading {
    margin: 1rem 0;
}

.note-detail__sections-heading {
    display: flex;
    align-items: center;
    margin-top: 2rem;
}

.note__more-btn {
    margin-left: .5rem;
}

.note-detail__sections-heading h2, .note-detail__sections-heading button {
    justify-self: center;
}

.note-detail__sections-heading h2 {
    margin: 0;
}

.note-detail__sections-heading button {
    margin-left: .5rem;
}

.note-detail__meta {
    color: var(--color-grey-dark);
    text-align: center;
}

.note__edit {
    margin-left: 1rem;
}

/* SECTIONS */
.section__header {
    display: flex;
    align-items: center;
    margin: 1rem 0 .5rem;
}

.section__header h3 {
    margin: 0;
}

.add-section-btn {
    display: inline;
}

.section__times {
    color: var(--color-grey-dark);
    margin: 0 .5rem;
}

/* COMMENT */
.comment {
    background: var(--color-grey);
    border-radius: var(--border-radius-primary);
    margin-bottom: 1rem;
    padding: 1rem;
}

.comment__upper-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.comment.rythmic {
    background-color: var(--color-orange);
}

.comment.melodic {
    background-color: var(--color-yellow);
}

.comment.harmonic {
    background-color: var(--color-blue);
}

.comment.sound-design {
    background-color: var(--color-purple);
}

.comment__tag {
    text-transform: capitalize;
    font-weight: 700;
    margin-bottom: .5rem;
}

.comment.rythmic .comment__tag {
    color: var(--color-orange-dark);
}

.comment.melodic .comment__tag {
    color: var(--color-yellow-dark);
}

.comment.harmonic .comment__tag {
    color: var(--color-blue-dark);
}

.comment.sound-design .comment__tag {
    color: var(--color-purple-dark);
}

.comment.untagged .comment__tag {
    color: var(--color-grey-dark);
}

/* MODAL */
#modal {
    background-color: #ddd;
    border-radius: var(--border-radius-primary);
    margin: 50vh 50vw 0;
    padding: 1.5rem;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 1;
}

/* FORMS */
form input, form textarea, form select {
    color: var(--color-primary);
    font-size: 18px;
    margin-bottom: 1.25rem;
    padding: .5rem;
    border: none;
}

form select, form label {
    display: block;
}

form select {
    background: var(--color-pure-white);
}

#noteKey, #scale {
}

form input[type=submit] {
    margin-bottom: 0;
}

.note-form {
    display: flex;
    flex-direction: column;
}

/* BUTTONS */
input[type=submit] {
    background-color: var(--color-pure-white);
    padding: .6rem .5rem .65rem;
}

input[type=submit]:hover {
    background-color: var(--color-grey);
    color: var(--color-primary);
    cursor: pointer;
    padding: .6rem .5rem .65rem;
}

.btn {
    background: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: .35rem .45rem .25rem;
}

.btn:hover {
    background: rgba(255, 255, 255, .5);
    cursor: pointer;
}

.close-btn-wrapper {
    display: flex;
    justify-content: end;
}

.create-btn {
    align-self: end;
    background-color: var(--color-pure-white);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    height: 50px;
    position: sticky;
    bottom: 1.5rem;
    margin-bottom: 1rem;
    width: 50px;
}

.create-btn svg {
    align-self: center;
}

/* UTILS */
.hidden {
    display: none;
    color: red;
}

/* BREAKPOINTS */

@media only screen and (min-width: 768px) {
    main {
        padding: 0 var(--tablet-side-padding);
    }

    header {
        padding: 2rem var(--tablet-side-padding);
    }
}

@media only screen and (min-width: 992px) {
    main {
        padding: 0 var(--laptop-side-padding);
    }

    header {
        padding: 2rem var(--laptop-side-padding);
    }

}

@media only screen and (min-width: 1200px) {
    main {
        padding: 0 var(--desktop-side-padding);
    }

    header {
        padding: 2rem var(--desktop-side-padding);
    }
}
